.WeatherDashboard {
  text-align: center;
  margin: 0 auto;
}

body {
  font-family: 'Raleway', sans-serif;
  color: #2d3436;
  text-align: center;
  width: 100%;
}

/* Current Weather Styling */
.main-icon,
.big-data h2 {
  font-size: 6em;
  border-radius: 50%;
  padding: 20px;
  margin: 20px 0 20px 0;
  display: inline-block;
  border-top: 20px;
}

.big-data h2 {
  border-left: 1px solid #2d3436;
  border-radius: 0;
}

.big-data h2,
.main-city,
.main-description {
  font-weight: 400;
}

/* .main-content {
  display: contents;
} */

.main-description {
  font-size: 1rem;
}

.main-city {
  font-size: 1.5rem;
}
.main-date {
  font-size: 1.75rem;
  font-weight: 500;
  padding: 10px 0;
}

/* Color Styling Main Section */

.boiling {
  background: #ff7675;
}

.hot {
  background: #fab1a0;
}

.warm {
  background: #fce498;
}

.perfect {
  background: #6ac3a4;
}

.cool {
  background: #67c6bb;
}

.freezing {
  background: #80bcf8;
}

/* Color Styling Forecast Section */

.boiling-forecast {
  background: #d63031;
}

.hot-forecast {
  background: #e17055;
}

.warm-forecast {
  background: #fdcb6e;
}

.perfect-forecast {
  background: #15a57a;
}

.cool-forecast {
  background: #22a39c;
}

.freezing-forecast {
  background: #278fdf;
}

/* Forecast Area Styling */

.horiz-menu {
  height: 150px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.forecast-cell {
  height: 150px;
  display: block !important;
  padding: 4 10px !important;
  text-align: center !important;
}

.forecast-area {
  width: 100%;
}

.forecast-cell > i {
  font-size: 3em;
  padding: 15px 0;
}

.forecast-cell > p {
  font-size: 0.7em;
  margin: 0;
}

.temp-text {
  font-size: 1.5em;
}

.forecast-cell h5 {
  display: block;
  margin: 0;
}

/* Menu Bar Styling */

.app-bar-logo {
  width: 70px;
  height: 70px;
  margin-left: -5px;
}

.menu {
  padding: 0 !important;
  font-family: 'Raleway', sans-serif !important;
  font-weight: 400;
}

/* Menu Font */

.location-button {
  min-width: 25% !important;
}

.weather-details {
  font-family: 'Raleway', sans-serif !important;
}

.weather-details > tbody > tr > td {
  color: #2d3436 !important;
  font-size: 1.2em;
  letter-spacing: 0.1em;
}

.whole-details-area h4 {
  font-size: 2em;
  margin: 10px auto;
}

button.add-button {
  position: absolute;
  right: 0px;
}

svg.MuiSvgIcon-root-103 {
  color: black;
}
