* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

.header {
  height: 56px !important;
  width: 100%;
  background-color: #3b78e7 !important;
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  .brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .navbar-collapse {
  }
  .menuLinks {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-right: auto !important;

    .MuiListItem-root {
      padding-right: 8px;
      padding-left: 8px;
      font-size: 14px;
      a {
        text-decoration: none;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .MuiToolbar-root {
    min-height: 56px !important;
    //   background-color: #3b78e7 !important;
  }
}

// .MuiAppBar-colorPrimary {
//   background-color: #3b78e7 !important;
// }

// .MuiDrawer-paperAnchorLeft {
//   background-color: #3b78e7 !important;
// }
.drawerLinks {
  .MuiListItem-root {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 14px;
    width: 200px;
    a {
      text-decoration: none;
      font-weight: 500;
      color: black;
    }
  }
}
.collapsedMenuIcon {
  color: white !important;
}
.collapse:not(.show) {
  display: none;
}
.navbar {
  justify-content: space-between !important;
}
@media (min-width: 600px) {
  .header {
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .collapsedMenuIcon {
      display: none;
    }
    .navbar {
      // justify-content: space-evenly;
    }
  }
}
.imageOverride {
  display: block;
  width: 100%;
}

.carousel__dot-group {
  .carousel__dot {
    margin: 0 1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    padding: 0;
    border: 0;
    background-color: black;
  }
  .carousel__dot--selected {
    background-color: gray;
  }
}

.brandContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .brandBar {
    display: flex;
    width: 100%;
    background: linear-gradient(
        to right,
        #000000 0,
        #000000 50%,
        #facd01 50%,
        #facd01 85%,
        #d8262f 85%,
        #d8262f
      )
      no-repeat;
    height: 7px;
  }
  a {
    color: black;
    text-decoration: none;
    padding-right: 10px;
  }
}

.appBody {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  padding: 0 10px 10px;
  // background-color: rgba(255, 255, 255, 1);
  // background-color: rgba(255, 255, 255, 0.75);
}

.bg-dev {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='150px' width='160px' style='background-color: white'><text transform='translate(20, 150) rotate(-45)' fill='rgb(245,45,45)' fill-opacity='0.5' font-size='20' >DEVELOPMENT SITE</text></svg>");
}
.bg-training {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='150px' width='160px' style='background-color: white'><text transform='translate(20, 150) rotate(-45)' fill='rgb(245,45,45)' fill-opacity='0.5' font-size='20' >TRAINING SITE</text></svg>");
}

.googleSignInDark {
  background-image: url('./images/btn_google_signin_dark_normal_web@2x.png');
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  width: 191px;
  height: 46px;
  background-size: contain;
  &:focus,
  &:hover {
    background-image: url('./images/btn_google_signin_dark_focus_web@2x.png');
    cursor: pointer;
  }
  &:active {
    background-image: url('./images/btn_google_signin_dark_pressed_web@2x.png');
    cursor: pointer;
  }
}
.googleSignInLight {
  background-image: url('./images/btn_google_signin_light_normal_web@2x.png');
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  width: 191px;
  height: 46px;
  background-size: contain;
  &:focus,
  &:hover {
    background-image: url('./images/btn_google_signin_light_focus_web@2x.png');
    cursor: pointer;
  }
  &:active {
    background-image: url('./images/btn_google_signin_light_pressed_web@2x.png');
    cursor: pointer;
  }
}
.microsoftSignInLight {
  background-image: url('./images/signinmicrosoft_lightmode.png');
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  width: 191px;
  height: 46px;
  background-size: contain;
  &:focus,
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: pointer;
  }
}
.microsoftSignInDark {
  background-image: url('./images/signinmicrosoft_darkmode.png');
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  width: 191px;
  height: 46px;
  background-size: contain;
  &:focus,
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: pointer;
  }
}

#lightboxBackdrop {
  z-index: 1000 !important;
}

.Toastify__toast-body {
  word-break: break-word;
}

.subcollectionButtons,
.stickyButtons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}
// .appBody div:not([class]):not([id]) {
//   background-color: rgba(255, 255, 255, 0.8);
// }
